<template>
    <Select v-model="locale" :options="langs" optionLabel="name" optionValue="code" placeholder="Select a language"/>
</template>

<script setup>
    const { locale } = useI18n()

    const langs = computed(() => {
        return [
            { name: 'English', code: 'en' },
            { name:  'Spanish', code: 'es' },
            { name:  'Portuguese', code: 'pt' }
        ]
    })

</script>